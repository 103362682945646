
import { Routes, Route, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import About from './about';

import TrackerPublic from './tracker-public';
// import TrackerKRNLS from './pages/tracker-krnls-wip';
import TrackerKRNLS from './tracker-krnls';
function App() {

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Check if the user is on a mobile device
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setIsMobile(true);
    }
    }, []);

    const appStyle = {
      textAlign: 'center',
      backgroundColor: '#f0f0f0',
      padding: '20px',
  };

  return (
    <div className="App"  style={appStyle}>
        {isMobile && (
                <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                    <p>Sorry! desktop only for now.</p>
                </div>
            )}
      <nav>
      <Link to="/about">About</Link> | <Link to="/tracker-public">TrackerPublic</Link> | <Link to="/tracker-krnls">TrackerKrnls</Link> 
      </nav>

      <Routes>
        <Route path="/" element={<TrackerPublic />} />
        <Route path="/about" element={<About />} />
        <Route path="/tracker-public" element={<TrackerPublic />} />
        <Route path="/tracker-krnls" element={<TrackerKRNLS />} />
      </Routes>
      <div> 
      </div>
    </div>
  );
}



export default App;
