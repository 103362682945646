import React, { useEffect, useState, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import DataTable from 'react-data-table-component';

const supabaseUrl = 'https://kwlyjhzcptawylkehuru.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt3bHlqaHpjcHRhd3lsa2VodXJ1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMzc3NzkzNywiZXhwIjoyMDQ5MzUzOTM3fQ.6dRzPHLrrQTwU7EbYdht3X5ohbfsEeypKQOG7NC_5Jk';
const supabase = createClient(supabaseUrl, supabaseKey);
const customStyles = {
    table: {
        style: {
            tableLayout: 'fixed', // Fix the table layout to prevent squishing
            width: '200%', // Increase the width to make the table longer
        },
    },
    header: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#333',
            backgroundColor: '#f1f1f1',
        },
    },
    rows: {
        style: {
            minHeight: '50px', // Override the row height
        },
    },
    cells: {
        style: {
            fontSize: '12px',
            whiteSpace: 'normal', // Prevent cell content from wrapping
            textAlign: 'center', // Ensure text is centered
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Allow header text to wrap
            // textOverflow: 'ellipsis', // Handle long text gracefully
            // overflow: 'visible', // Ensure overflow is visible
        },
    },
    pagination: {
        style: {
            backgroundColor: '#f9f9f9',
            borderTop: '1px solid #ddd',
        },
    },
    context: {
        style: {
            background: '#000000',
            text: '#000000',
        },
    },
};

const TrackerPublic = () => {
    const [trackTest1, setTrackTest1] = useState([]);
    const [trackTest2, setTrackTest2] = useState([]);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    
    // Filters for Table 1
    const [searchQueryTable1, setSearchQueryTable1] = useState('');
    const [minValueTable1, setMinValueTable1] = useState('');
    const [maxValueTable1, setMaxValueTable1] = useState('');
    const [selectedColumnTable1, setSelectedColumnTable1] = useState('');
    const [selectedGroupTable1, setSelectedGroupTable1] = useState('');

    // Filters for Table 2
    const [searchQueryTable2, setSearchQueryTable2] = useState('');
    const [minValueTable2, setMinValueTable2] = useState('');
    const [maxValueTable2, setMaxValueTable2] = useState('');
    const [selectedColumnTable2, setSelectedColumnTable2] = useState('');
    const [selectedGroupTable2, setSelectedGroupTable2] = useState('');

    const dropDuplicates = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const serializedItem = JSON.stringify(item); // Serialize the object
            if (seen.has(serializedItem)) {
                return false; // Duplicate found
            }
            seen.add(serializedItem);
            return true; // Unique item
        });
    };

    // Fetch data from the 'track-test' table on component load
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data for Table 1
                const { data: dataTable1, error: errorTable1 } = await supabase.from('tracker-public')
                    .select('*')
                    .gt('Avg X PNL', 1000)
                    .gt('Avg SOL PNL', 0);
                if (errorTable1) throw errorTable1;

                const uniqueDataTable1 = dropDuplicates(dataTable1 || []);
                setTrackTest1(uniqueDataTable1 || []); // For Table 1

            } catch (err) {
                setError(err);
            }

            try {
                // Fetch data for Table 2 separately
                const { data: dataTable2, error: errorTable2 } = await supabase.from('tracker-public')
                    .select('*')
                if (errorTable2) throw errorTable2;

                const uniqueDataTable2 = dropDuplicates(dataTable2 || []);
                setTrackTest2(uniqueDataTable2 || []); // For Table 2

            } catch (err) {
                setError(err);
            }
        };

        fetchData();
    }, []);

    // Function to copy wallet addresses to clipboard
    const copyAddresses = () => {
        const addresses = selectedRows.map(row => row.WalletAddress).join(', ');
        navigator.clipboard.writeText(addresses)
            .then(() => alert('Addresses copied to clipboard!'))
            .catch(err => console.error('Failed to copy: ', err));
    };

    const contextActions = React.useMemo(() => {
        return []; // No actions
    }, [selectedRows]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'short', // 'short' for abbreviated month
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Set to false for 24-hour format
        });
    };

    // Define columns dynamically
    const columns = trackTest1.length
    ? Object.keys(trackTest1[0]).map((key) => ({
          name: (
              <div className="column-header" title={key}>
                  {key}
              </div>
          ),
          selector: (row) => row[key],
          sortable: true,
          cell: (row) => {
            if (key === 'WalletAddress') { // Check if the column is WalletAddress
                return (
                    <a href={`https://www.birdeye.so/profile/${row.WalletAddress}?chain=solana&duration=30D`} target="_blank" rel="noopener noreferrer">
                        {row.WalletAddress.substring(0, 6)}... {/* Show first 8 characters */}
                    </a>
                );
            } 
            return row[key]; // Default rendering for other columns
        }
      }))
    : [];

    // Get unique groups from the data
    const uniqueGroups1 = [...new Set(trackTest1.map(row => row.Group))];
    const uniqueGroups2 = [...new Set(trackTest2.map(row => row.Group))];

    // Filter data for the first table (e.g., Avg X PNL > 2000)
    const filteredDataTable1 = trackTest1.filter(row => 
        Object.values(row).some(value => 
            String(value).toLowerCase().includes(searchQueryTable1.toLowerCase())
        ) && 
        (minValueTable1 === '' || row[selectedColumnTable1] >= parseFloat(minValueTable1)) &&
        (maxValueTable1 === '' || row[selectedColumnTable1] <= parseFloat(maxValueTable1)) &&
        (selectedGroupTable1 === '' || row.Group === selectedGroupTable1)
    );

    // Filter data for the second table (e.g., Avg X PNL < 1000)
    const filteredDataTable2 = trackTest2.filter(row => 
        Object.values(row).some(value => 
            String(value).toLowerCase().includes(searchQueryTable2.toLowerCase())
        ) && 
        (minValueTable2 === '' || row[selectedColumnTable2] >= parseFloat(minValueTable2)) &&
        (maxValueTable2 === '' || row[selectedColumnTable2] <= parseFloat(maxValueTable2)) &&
        (selectedGroupTable2 === '' || row.Group === selectedGroupTable2)
    );

    return (
        <>
        <div>
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '3%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' 
        }}>
            <a href="https://poofcoin.xyz/"> <img src="poof.png" alt="POOF" style={{ maxWidth: '180px', height: 'auto' }} /> </a>
        </div>
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '17%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' 
        }}>
            <a href="https://www.ayaonsui.xyz/"> <img src="aya.png" alt="aya" style={{ maxWidth: '300px', height: 'auto' }} /> </a>
        </div>
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            right: '18%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' 
        }}>
           <a href="https://x.com/KernelsNFT"> <img src="pepedance.gif" alt="pepe" style={{ maxWidth: '215px', height: 'auto' }} /> </a>
        </div>
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            right: '1%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' 
        }}>
            <img src="yourtoken.png" alt="yourtoken" style={{ maxWidth: '250px', height: 'auto' }} /> 
        </div>
            <h1>KERNELS DOT FUN</h1>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
            
            {/* Filters for Table 1 */}
            <input 
                type="text" 
                placeholder="Search..." 
                value={searchQueryTable1} 
                onChange={(e) => setSearchQueryTable1(e.target.value)} // Search input field
            />
            <br></br><br></br>
            <select 
                value={selectedGroupTable1} 
                onChange={(e) => setSelectedGroupTable1(e.target.value)} // Group selection dropdown
            >
                <option value="">Select Group</option>
                {uniqueGroups1.map((group, index) => (
                    <option key={index} value={group}>{group}</option>
                ))}
            </select>
            <select 
                value={selectedColumnTable1} 
                onChange={(e) => setSelectedColumnTable1(e.target.value)} // Column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable1} 
                onChange={(e) => setMinValueTable1(e.target.value)} // Min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable1} 
                onChange={(e) => setMaxValueTable1(e.target.value)} // Max value input
            />
            <button onClick={copyAddresses} disabled={selectedRows.length === 0}>Copy Address</button>
            <br />
            {/* First DataTable */}
            <h2 style={{display: 'block', color: 'green' }}>avg PNL OVER 1000X can you believe it?</h2>

            <DataTable
                columns={columns}
                data={filteredDataTable1} // Use filtered data for table 1
                pagination
                customStyles={customStyles}
                selectableRows 
                contextActions={contextActions}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                dense
            /> 
            </div> 
 
            <div>
            <h1>KERNELS DOT FUN</h1>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
            
            {/* Filters for Table 2 */}
            <input 
                type="text" 
                placeholder="Search..." 
                value={searchQueryTable2} 
                onChange={(e) => setSearchQueryTable2(e.target.value)} // Search input field
            />
            <br></br><br></br>
            <select 
                value={selectedGroupTable2} 
                onChange={(e) => setSelectedGroupTable2(e.target.value)} // Group selection dropdown
            >
                <option value="">Select Group</option>
                {uniqueGroups2.map((group, index) => (
                    <option key={index} value={group}>{group}</option>
                ))}
            </select>
            <select 
                value={selectedColumnTable2} 
                onChange={(e) => setSelectedColumnTable2(e.target.value)} // Column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2} 
                onChange={(e) => setMinValueTable2(e.target.value)} // Min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2} 
                onChange={(e) => setMaxValueTable2(e.target.value)} // Max value input
            />
            <button onClick={copyAddresses} disabled={selectedRows.length === 0}>Copy Address</button>
            <br />


            {/* Second DataTable */}
            <h2 style={{display: 'block', color: 'hotpink' }}>have fun! -- get kernels for more features and more data!</h2>
            <DataTable
                columns={columns}
                data={filteredDataTable2} // Use filtered data for table 2
                pagination
                customStyles={customStyles}
                selectableRows 
                contextActions={contextActions}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                dense
            />
        </div>
        </>
    );
};

export default TrackerPublic;