import React from 'react';

const About = () => {
    return (
        <>
        <div><br></br> <br></br> <br></br> <br></br></div>

            <h1>WHO CARES BRO KERNELS DOT FCKING FUN</h1>
            <h4> Kernels N01 <a href="https://magiceden.us/marketplace/krnlsn01">magic eden </a>
                  |  Kernels WAGMI <a href="https://magiceden.us/marketplace/krnlsn01">magic eden </a> </h4>
            <img src="/AD.png" alt="AD" style={{ width: '200px', height: '200px' }}/>
            <h4> dev @findbleh</h4>
            <h2> may the force be with you</h2>
            <h2> have fun</h2>
            <h2> WAGMI </h2> 


        </>
    );
}


export default About;

