import React from 'react';

const TrackerKRNLS = () => {
    return (
        <>
        <div><br></br> <br></br> <br></br> <br></br></div>

        <img src="yoyo.gif" alt="yoyo" style={{ width: '200px', height: '200px' }}/>
            <h2>you know its fresh so close to deploy get yo kernels now !!</h2>
            <h4> Kernels N01 <a href="https://magiceden.us/marketplace/krnlsn01">magic eden</a>  |  Kernels WAGMI <a href="https://magiceden.us/marketplace/krnlsn01">magic eden </a> </h4>
        </>
    );
}


export default TrackerKRNLS;

